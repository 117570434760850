/* Importing the node modules, child components, services and controllers used 
   inside AbcHeader component */
import { Link, NavLink } from "react-router-dom";

import MainController from "../../../controller/mainController";
/* AbcHeader Component initialization */
class IntutiveSurgicalInternsHeader extends React.Component {
  /* Initializing objects of its AbcHeader class */
  constructor(props) {
    super(props);
    /* AbcHeader Component State variables Initialization */
    this.state = {
      name: "",
      company: "",
      location: "",
      phone: "",
      email: "",
      bookingDetails: {},
      BookingData: {},
    };
  }
  /* It is invoked immediately after a component is mounted */
  componentDidMount() {
    MainController.removeClassOnNavCollapse();
  }
  /* It is invoked to return html content */
  render() {
    return (
      <div>
        <div className="profile_submenu">
          <div className="container">
            <nav className="navbar navbar-inverse">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#guest-login"
                  aria-expanded="false"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                {/* <img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Logo.png"} alt="" className="nike_list_img_mo" /> */}
              </div>
              <div className="collapse navbar-collapse" id="guest-login">
                <ul className="nav navbar-nav">
                  <li className="px-3">
                    <NavLink
                      to="/company/IntuitiveSurgical-interns/home"
                      activeClassName="active-link"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="px-3">
                    <NavLink
                      to="/company/IntuitiveSurgical-interns/about-suite-america"
                      activeClassName="active-link"
                    >
                      About SuiteAmerica
                    </NavLink>
                  </li>
                  <li className="px-3">
                    <NavLink
                      to="/company/IntuitiveSurgical-interns/how-it-works"
                      activeClassName="active-link"
                    >
                      How it Works
                    </NavLink>
                  </li>
                  <li className="px-3">
                    <NavLink
                      to="/company/IntuitiveSurgical-interns/FAQs"
                      activeClassName="active-link"
                    >
                      FAQs
                    </NavLink>
                  </li>
                  <li className="px-3">
                    <NavLink
                      to="/company/IntuitiveSurgical-interns/whats-included"
                      activeClassName="active-link"
                    >
                      What's Included
                    </NavLink>
                  </li>
                  <li className="px-3">
                    <NavLink
                      to="/company/IntuitiveSurgical-interns/reservation-form"
                      activeClassName="active-link"
                    >
                      Request Form
                    </NavLink>
                  </li>
                  <li className="px-3">
                    <NavLink
                      to="/company/IntuitiveSurgical-interns/locations"
                      activeClassName="active-link"
                    >
                      Locations
                    </NavLink>
                  </li>
                </ul>
                {/* <img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Logo.png"} alt="" className="nike_list_img" /> */}
              </div>
            </nav>
          </div>
        </div>
        <div className="subHeaderHeight"></div>
      </div>
    );
  }
}
export default IntutiveSurgicalInternsHeader;
