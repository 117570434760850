import React from "react";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";

function Disclaimer() {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="bg-grey py-5">
      <div className="container">
        <p className="text-gray-200">
          <b className="text-dark">Disclaimer : </b> Ensure your housing
          registration is completed a minimum of 45 days prior to the
          commencement of your internship. Accommodations are allocated on a
          first-come, first-served basis, and historically, housing reaches
          capacity early in the summer.
        </p>
      </div>
      {currentPath != "/company/LinkedIn-interns/reservation-form" && (
        <div className="text-center SAweb_updateUi mt-4">
          <Link to="/company/LinkedIn-interns/reservation-form">
            <button className="btn black-btn">Sign Me Up!</button>
          </Link>
        </div>
      )}
    </div>
  );
}

export default Disclaimer;
