import { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import GuestHeader from "./common/guestHeader";
import SubFooter from "./common/subFooter";
import Footer from "./common/footer";

const protectedRoutes = [
  "/company/LinkedIn-interns/home",
  "/company/LinkedIn-interns/about-suite-america",
  "/company/LinkedIn-interns/how-it-works",
  "/company/LinkedIn-interns/whats-included",
  "/company/LinkedIn-interns/FAQs",
  "/company/LinkedIn-interns/locations",
  "/company/LinkedIn-interns/reservation-form",
  "/company/Wellsfargo-interns/home",
  "/company/Wellsfargo-interns/about-suite-america",
  "/company/Wellsfargo-interns/how-it-works",
  "/company/Wellsfargo-interns/whats-included",
  "/company/Wellsfargo-interns/FAQs",
  "/company/Wellsfargo-interns/locations",
  "/company/Wellsfargo-interns/reservation-form",
  "/company/IntuitiveSurgical-interns/home",
  "/company/IntuitiveSurgical-interns/about-suite-america",
  "/company/IntuitiveSurgical-interns/how-it-works",
  "/company/IntuitiveSurgical-interns/whats-included",
  "/company/IntuitiveSurgical-interns/FAQs",
  "/company/IntuitiveSurgical-interns/locations",
  "/company/IntuitiveSurgical-interns/reservation-form",
];
const ProtectedRoute = ({ path, component: Component }) => {
  const [accessCode, setAccessCode] = useState("");
  const [isValidCode, setIsValidCode] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const storedCode = path.includes("LinkedIn-interns/")
      ? localStorage.getItem("linkedIn-Interns-accessCode")
      : (path.includes("Wellsfargo-interns/")
      ? localStorage.getItem("Wellsfargo-interns-accessCode")
      : localStorage.getItem("IntuitiveSurgical-interns-accessCode"));

    const storedTimestamp = path.includes("LinkedIn-interns/")
      ? localStorage.getItem("LinkedIn-accessTimestamp")
      : (path.includes("Wellsfargo-interns/")
      ? localStorage.getItem("Wellsfargo-accessTimestamp")
      : localStorage.getItem("IntuitiveSurgical-accessTimestamp"));

    if (
      storedCode &&
      isValidAccessCode(storedCode) &&
      isValidTimestamp(storedTimestamp)
    ) {
      setIsValidCode(true);
    }
  }, []);

  const isValidAccessCode = (code) => {
    if (path.includes("LinkedIn-interns/")) {
      return code.toLowerCase() === "li2025";
    } else if (path.includes("Wellsfargo-interns/")) {
      return code.toLowerCase() === "2025wl";
    } else {
      return code.toLowerCase() === "is2025";
    }
  };

  const isValidTimestamp = (timestamp) => {
    const expirationTime = 24 * 60 * 60 * 1000;
    return Date.now() - parseInt(timestamp) < expirationTime;
  };

  const handleAccessCodeSubmit = (e) => {
    e.preventDefault();
    if (isValidAccessCode(accessCode)) {
      path.includes("LinkedIn-interns/")
        ? localStorage.setItem("linkedIn-Interns-accessCode", accessCode)
        : (path.includes("Wellsfargo-interns/")
        ? localStorage.setItem("Wellsfargo-interns-accessCode", accessCode)
        : localStorage.setItem(
            "IntuitiveSurgical-interns-accessCode",
            accessCode
          ));
      path.includes("LinkedIn-interns/")
        ? localStorage.setItem("LinkedIn-accessTimestamp", Date.now())
        : (path.includes("Wellsfargo-interns/")
        ? localStorage.setItem("Wellsfargo-accessTimestamp", Date.now())
        : localStorage.setItem("IntuitiveSurgical-accessTimestamp", Date.now()));
      setIsValidCode(true);
    } else {
      setError("Invalid access code");
    }
  };

  if (!isValidCode && protectedRoutes.includes(path)) {
    return (
      <div className="corporateHousing_Updates">
        <GuestHeader />
        <div className="linkedIn-interns">
          {path.includes("LinkedIn-interns/") ? (
            <h2>Welcome LinkedIn Interns!</h2>
          ) : path.includes("Wellsfargo-interns/") ? (
            <h2>Welcome Wellsfargo Interns!</h2>
          ) : (
            <h2>Welcome Intuitive Surgical Interns!</h2>
          )}
          <h3>Please enter Access code to view</h3>
          <form onSubmit={handleAccessCodeSubmit}>
            <input
              type="text"
              value={accessCode}
              onChange={(e) => {
                setAccessCode(e.target.value);
                setError("");
              }}
            />
            <button type="submit" className="btn btn-dark">
              Submit
            </button>
          </form>
          {error && <p className="error">{error}</p>}
        </div>
        <SubFooter />
        <Footer />
      </div>
    );
  }

  return (
    <Route exact path={path} render={(props) => <Component {...props} />}>
      {path.includes("IntutiveSurgical-interns/") && (
        <Redirect to={path.replace("IntutiveSurgical", "IntuitiveSurgical")} />
      )}
    </Route>
  );
};

export default ProtectedRoute;
