/* Importing the node modules, child components, services and controllers used 
   inside NikeOnlineManagement component */
import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import IntutiveSurgicalInternsHeader from "./common/IntutiveSurgicalInternsHeader";
import GuestHeader from "../../common/guestHeader";
import Footer from "../../common/footer";
import { Link } from "react-router-dom";
import Disclaimer from "./common/disclimar";
import SubFooterIntutiveSurgical from "./common/subFooterIntutiveSurgical";

/* NikeOnlineManagement Component initialization */
class IntutiveSurgicalInternsOnlineManagement extends React.Component {
  /* Initializing objects of its NikeOnlineManagement class */
  constructor(props) {
    super(props);
  }
  /* It is invoked immediately before mounting occurs */
  componentWillMount() {
    MainController.getInitialInformation();
  }
  /* It is invoked to return html content */
  render() {
    return (
      <div className="corporateHousing_Updates">
        <GuestHeader />

        <IntutiveSurgicalInternsHeader />
        {/* <div class="in-intern-home-banner SAweb_updateUi">
          <img
            class="bannerImg"
            src="/assets/images/linkedIn-interns/howItsWorksBanner.png"
            alt=""
            width="100%"
          />
          <div class="in-intern-home-content">
            <div class="container">
              <div class="content-width paraCount" style={{ width: "60%" }}>
                <h3 class="text-white mrg-top-50 mb-4">
                  So, how does this work?
                </h3>

                <p class="text-white p m-0">
                  1. Review the FAQ’s to ensure our shared housing solutions are
                  a great fit for you
                </p>
                <p class="text-white p m-0">
                  2. Fill out the questionnaire to select your rooming
                  preference
                </p>
                <p class="text-white p m-0">
                  3. Approx 30 days prior to move in, SuiteAmerica roommate
                  matches you & offers you a lease
                </p>
                <p class="text-white p m-0">
                  4. You sign the lease & submit payment for the first month’s
                  rent
                </p>
                <p class="text-white p m-0">
                  5. SuiteAmerica sends you the move in info!
                </p>
                

                <p class="text-white p mt-4">That's it!</p>

                <Link to="/company/IntutiveSurgical-interns/reservation-form">
                  <button class="btn">Let's Get Started</button>
                </Link>
              </div>
            </div>
          </div>
        </div> */}

        <div class="SAweb_updateUi my-6 in-intern-content bg-white">
          <div class="container">
            <div className="stepListViewMobileView">
              <ul class="stepListView">
                <li>
                  <span>1</span> Step One
                  <img
                    src="/assets/images/linkedIn-interns/triangleImage.svg"
                    alt=""
                  />
                </li>
                <li>
                  <span>2</span> Step Two
                  <img
                    src="https://www.suiteamerica.com/assets/newUI-images/logo/SA_S-logo-B.svg"
                    alt=""
                  />
                </li>
                <li>
                  <span>3</span> Step Three
                  <img
                    src="/assets/images/linkedIn-interns/triangleImage.svg"
                    alt=""
                  />
                </li>
                <li>
                  <span>4</span> Step Four
                  <img
                    src="https://www.suiteamerica.com/assets/newUI-images/logo/SA_S-logo-B.svg"
                    alt=""
                  />
                </li>
                <li>
                  <span>5</span> Step Five
                  <img
                    src="/assets/images/linkedIn-interns/triangleImage.svg"
                    alt=""
                  />
                </li>
              </ul>
              <ul class="stepListView text text-center">
                <li>
                  Review the FAQ's to ensure our shared housing solutions are a
                  good fit for you
                </li>
                <li>
                  Fill out the questionnaire to select your rooming preference
                </li>
                <li>
                  Approx 30 days prior to move in. SuiteAmerica roommate matches
                  you & offers you a lease
                </li>
                <li>
                  You sign the lease (Payment is due 14 days after move in)
                </li>
                <li>SuiteAmerica sends you the move in info & you move in</li>
              </ul>
            </div>
            <ul class="stepListView oneTial border-0">
              <li>
                <img
                  class=""
                  src="/assets/images/linkedIn-interns/triangleImageWithBuilding.svg"
                  alt=""
                />
                <b class="bi bi-check2-square mt-4">Task Complete!</b>
              </li>
            </ul>
            <ul class="stepListView text oneTial">
              <li>
                Disclaimer: SuiteAmerica bears no liability for disputes,
                accidents, security issues, damage, or any incidents that may
                occur between roommates. Residents are solely responsible for
                addressing and resolving such matters and serve as the official
                lease holders.
              </li>
            </ul>
            <div className="text-center">
              <Link to="/company/IntutiveSurgical-interns/FAQs">
                <button className="btn  black-btn">Review the FAQs</button>
              </Link>
            </div>
          </div>
        </div>
        <Disclaimer />
        {/* <SubFooter /> */}
        <SubFooterIntutiveSurgical />
        <Footer />
      </div>
    );
  }
}
export default IntutiveSurgicalInternsOnlineManagement;
